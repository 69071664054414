@import "./../../styles/mixins";
@import "./../../styles/theme";

.project-list {

  &__empty {
    font-size: 22px;
    padding: 5rem;
    text-align: center;

    @include lg {
      font-size: 18px;
    }
  }

  &__view {

    @include flex(ss);
    flex-wrap: wrap;
    //padding: $padding-tiny;

    &__item {

      //min-height: 70vh;
      max-height: 100vh;
      //padding: $padding-tiny;
      overflow: hidden;

      background-color: inherit;

      @include lg {
        width: 50% !important;
        min-height: 400px;
      }

      @include md {
        width: 100% !important;
      }

      &__inner {
        @include flex(ss);
        height: 100%;
        position: relative;

        &--text-relative {
          flex-direction: column;

          & > div {
            position: relative;
            //margin-top:0 !important;
            padding-top: 0;

            // temporary
            background-color: $color-black;
          }
        }
      }

      &--B {
        width: 100%;
        //width: calc(100% - #{$item_gap * 2});
      }

      &--M {
        width: 50%;
        //width: calc(50% - #{$item_gap * 2});
      }

      &--S {
        width: 250%;
        //width: calc(25% - #{$item_gap * 2});
      }

      &__image {

        width: 100%;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        overflow: hidden;

        //background-color: $white;
      }

      &__link {

        transition: opacity .3s ease-in-out;

        img {
          //zoom: 100%;
          transition: all 1s ease-in-out;
        }

        &:hover {
          opacity: .979 !important;

          img {
            //transform: scale(1.1) !important;
          }
        }
      }

      &__text {

        flex-direction: column;
        padding: 50px 0 0 0;
        position: absolute;
        z-index: 9;
        bottom:0;
        right:0;

        &--max-width {
          & > div {
            max-width: $slide-max-width;
          }
        }

        &--gradient {
          background: rgb(255,255,255);
          background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
          background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
          background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
          //background: rgb(88,88,88);
          //background: linear-gradient(0deg, rgba(255, 255, 255, 0.46) 0%, rgba(255, 255, 255, 0) 100%);
        }

        //background: rgb(88,88,88);
        //background: linear-gradient(0deg, rgba(88,88,88,0.7875350823923319) 0%, rgba(255,255,255,0) 100%);

        //background: rgb(68,68,68);
        //background: linear-gradient(to bottom,rgba(0,0,0,0),rgba(0,0,0,.8)) 0 102% no-repeat;

        width: 100%;

        color: $text-color-default;

        &--color {
          &-W {
            color: $text-color-default;
          }
          &-G {
            color: $text-color-dark;
          }
          &-B {
            color: $text-color-black;
          }
        }

        &__inner {
          //@include flex(rs);
          //max-width: $slide-max-width;
          margin-left: auto;

          transition: opacity .3s ease-in-out;

          &:hover {
            opacity: .6;
          }
        }

        &--title, &--excerpt {
          margin: 10px 25px;
        }

        &--title {

          margin: 20px 25px 15px 25px;
          font-family: $font-main;

          span {
            font-size: 30px;
            font-weight: 400;
            line-height: 38px;

            @include lg {
              font-size: 24px;
              line-height: 26px;
            }
          }
        }

        &--excerpt {
          font-size: 22px;
          font-weight: 300;
          line-height: 26px;

          padding: 0;
          @include text-max-lines(2);

          @include xl {
            font-size: 18px;
            line-height: 20px;
          }
        }

        &--R {
          text-align: right;
        }
        &--C {
          text-align: center;
        }
        &--L {
          text-align: left;

          & > div {
            margin-left:unset;
            margin-right:auto;
          }
        }
      }

      //&__image, &__text {
      //  @include xxl {
      //    width: 50%;
      //  }
      //
      //  @include lg {
      //    width: 100%;
      //  }
      //}
    }
  }
}