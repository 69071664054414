@import "./../../styles/mixins";

.fetcher {

  &-paginate, &-wrapper {
    @extend %full-height-header;
  }

  &-wrapper {
    height: 100%;
    opacity: 0;
    transition: opacity .3s;

    &--loaded {
      opacity: 1;
    }
  }
}