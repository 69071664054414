@import "./../../styles/theme";
@import "./../../styles/mixins";

@keyframes example {
  from {width: 0; height: 0;}
  to {width: 100%; height: 100%;}
}

html body .coolimage {

  //animation-name: example;
  //animation-duration: .1s;

  //position: absolute;
  //left: 0;
  //right: 0;
  //bottom: 0;
  //top: 0;
  //margin:auto;

  transform: none !important;

  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;

  transition: opacity .3s ease-in-out;

  @include md {
    //transition: none !important;
  }

  &__loader {

    left:0;
    top:0;
    right:0;
    bottom:0;
    background-color: inherit;

    z-index: 10;
    &__inner {
      @include flex(cc);
      //span span {
      //  color: $yellow !important;
      //  background-color: $yellow !important;
      //}
    }
  }
}